import { Icon } from "./styled";
import { FCRodinaButton as Button } from "../Button";
import wa from "./whatsapp.png";

interface ContactUsButtonProps {
    children: React.ReactNode;
    phoneNumber: string;
}

export const ContactUsButton: React.FC<ContactUsButtonProps> = ({ children, phoneNumber }) => {
    return (
        <Button onClick={() => window.open(`https://wa.me/${phoneNumber}`, '_blank', "popup=1,width=320,height=260")}>
            <Icon src={wa} />
            {children}
        </Button>
    );
}
