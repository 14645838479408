import styled from 'styled-components';

export const LauncherPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    overflow: hidden;
    //background-color: #f3f3f3;
    background: url('https://fcrodina.com/i/background_new.png') no-repeat center center fixed;
`;