import { BACKEND_URL, buildApiUrl } from "..";
import { TeamContext } from "../../types/common";
import { MatchListDto, TournamentTableDto } from "../../types/stats";

export const getTournamentTable = async (tournamentId: number, seasonId: number, context?: TeamContext) => {
    const response = await fetch(BACKEND_URL + buildApiUrl('/stats/tournament-table', context), {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            tournamentId,
            seasonId
        })
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }
    return data as TournamentTableDto;
}

export const getMatchList = async (tournamentId: number, seasonId: number, teamId: number, context?: TeamContext) => {
    const response = await fetch(BACKEND_URL + buildApiUrl('/stats/match-list', context), {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            tournamentId,
            seasonId,
            teamId,
        })
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }
    return data as MatchListDto;
}