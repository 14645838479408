import { TeamContext } from "../common";

interface TableStats {
    teamId: number;
    matchesPlayed: number;
    wins: number;
    draws: number;
    losses: number;
    goalsFor: number;
    goalsAgainst: number;
    points: number;
}

interface TournamentTableItem extends TableStats {
    position: number;
    teamName: string;
    teamLogo: string;
}

export type TournamentTableDto = TournamentTableItem[];

export type StatsFilter = {
    tournamentId: number;
    seasonId: number;
    teamId?: number;
    mainScreenTableConfig?: {
        topTeams: number;
        bottomTeams: number;
    };
    mainScreenMatchesConfig?: {
        prevMatches: number;
    };
    teamName?: TeamContext;
};

export enum MatchResult {
    WIN = 'win',
    DRAW = 'draw',
    LOSS = 'loss',
    NOT_PLAYED = 'not_played',
}

export enum MatchStatus {
    UPCOMING = 'upcoming',
    IN_PROGRESS = 'in_progress',
    FINISHED = 'finished',
    СANCELLED = 'cancelled',
}

export interface MatchListItem {
    matchId: number;
    date: string;
    opponentTeamName: string;
    opponentTeamLogo: string;
    isHome: boolean;
    score: string;
    result: MatchResult;
    videoUrl: string;
    protocolUrl?: string;
    status: MatchStatus;
    tickets: string;
}

export type MatchListDto = MatchListItem[];
