import { useEffect, useState } from "react";
import { MatchListDto, MatchResult, MatchStatus } from "../../types/stats";
import { getMatchList } from "../../api/transport/statsTransport";
import { FCRodinaTable } from "../Table";
import { TeamTitle } from "../TeamTitle";
import {
    IconLink,
    LegendContainer,
    LegendItem,
    LegendMarker,
    LegendText,
    MatchResultContainer,
    MatchResultMarker,
    ScoreLink,
    TableDateContainer
} from "./styled";
import { VideoCameraOutlined } from "@ant-design/icons";
import { getMatchResultColor } from "./utils";
import { BuyTicketButton } from "./BuyTicket";
import { TeamContext } from "../../types/common";

interface MatchListTableProps {
    tournamentId: number;
    seasonId: number;
    teamId?: number;
    teamName?: TeamContext;
}

export const MatchListTable: React.FC<MatchListTableProps> = ({ tournamentId, seasonId, teamId, teamName }) => {
    const [mathcList, setMatchList] = useState<MatchListDto>([]);

    useEffect(() => {
        getMatchList(tournamentId, seasonId, teamId, teamName).then((data) => {
            setMatchList(data);
        });
    }, [seasonId, tournamentId, teamId, teamName]);

    const dataColumns = [
        { title: 'Дата', dataIndex: 'date', key: 'date', width: 110 },
        { title: 'Соперник', dataIndex: 'opponentTeamName', key: 'opponentTeamName' },
        { title: 'Тип матча', dataIndex: 'isHome', key: 'isHome' },
        { title: 'Счет', dataIndex: 'score', key: 'score' },
        { title: 'Видео', dataIndex: 'videoUrl', key: 'videoUrl' }
    ];

    const dataItems = mathcList.map((item) => {
        return dataColumns.map((column) => {
            let value = item[column.dataIndex];
            if (column.dataIndex === 'opponentTeamName') {
                return {
                    key: column.key,
                    value: (
                        <TeamTitle logoUrl={item.opponentTeamLogo} name={value} />
                    )
                }
            } else if (column.dataIndex === 'date') {
                const date = new Date(value).toLocaleDateString('ru-RU', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                });
                const time = new Date(value).toLocaleTimeString('ru-RU', {
                    hour: '2-digit',
                    minute: '2-digit',
                });
                return {
                    key: column.key,
                    value: (
                        <TableDateContainer>
                            {date}<br />{time}
                        </TableDateContainer>
                    )
                }
            } else if (column.dataIndex === 'score') {
                if (item.status === MatchStatus.СANCELLED || item.status === MatchStatus.UPCOMING) {
                    if (item.tickets) {
                        value = (
                            <BuyTicketButton url={item.tickets} />
                        );
                    } else {
                        value = '-';
                    }
                } else {
                    value = (
                        <MatchResultContainer>
                            <ScoreLink href={item.protocolUrl}>
                                {item.score}

                            </ScoreLink>
                            <MatchResultMarker $color={getMatchResultColor(item.result)} />
                        </MatchResultContainer>
                    );
                }
            } else if (column.dataIndex === 'isHome') {
                value = value ? 'Дома' : 'На выезде';
            } else if (column.dataIndex === 'videoUrl') {
                value = (
                    <IconLink href={value}>
                        <VideoCameraOutlined />
                    </IconLink>
                );
            }
            return {
                key: column.key,
                value
            }
        });
    });

    return (
        <>
            <LegendContainer>
                <LegendItem>
                    <LegendMarker $color={getMatchResultColor(MatchResult.NOT_PLAYED)} />
                    <LegendText>
                        Не сыграно
                    </LegendText>
                </LegendItem>
                <LegendItem>
                    <LegendMarker $color={getMatchResultColor(MatchResult.DRAW)} />
                    <LegendText>
                        Ничья
                    </LegendText>
                </LegendItem>
                <LegendItem>
                    <LegendMarker $color={getMatchResultColor(MatchResult.WIN)} />
                    <LegendText>
                        Победа
                    </LegendText>
                </LegendItem>
                <LegendItem>
                    <LegendMarker $color={getMatchResultColor(MatchResult.LOSS)} />
                    <LegendText>
                        Поражение
                    </LegendText>
                </LegendItem>
            </LegendContainer>
            <FCRodinaTable
                columns={dataColumns}
                data={dataItems}
            />
        </>
    );
}

