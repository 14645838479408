import { FC } from "react";
import { FCRodinaButton } from "../Button"
//import { TicketIcon } from "./styled";

interface BuyTicketButtonProps {
    url: string;
}

export const BuyTicketButton: FC<BuyTicketButtonProps> = ({ url }) => {
    return (
        <FCRodinaButton size="small" onClick={() => window.open(url)}>
            {/*<TicketIcon src="https://fcrodina.com/i/tickwh.png" alt="ticket"/>*/}
            Билеты
        </FCRodinaButton>
    );
}