import styled from 'styled-components';

export const UploadContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #133D5E;
    min-height: 300px;
`;

export const UploadText = styled.p`
    margin-top: 16px;
    text-align: center;
    margin: 0 0 4px;
    font-size: 16px;
`;

export const UpdateHint = styled.p`
    font-size: 14px;
    opacity: 0.6;
`;

export const UploadIcon = styled.div`
    font-size: 64px;
    margin-bottom: 16px;
`;