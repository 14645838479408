import React from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "./styled";

interface Column {
    title: string;
    dataIndex: string;
    key: string;
    left?: boolean;
    width?: number;
}

interface DataItem {
    key: string;
    value: string | React.ReactNode;
}

interface FCRodinaTableProps {
    columns: Column[];
    data: DataItem[][];
}

export const FCRodinaTable: React.FC<FCRodinaTableProps> = ({columns, data}) => {
    return (
        <Table>
            <Thead>
                <Tr>
                    {columns.map(c => (
                        <Th key={c.key} $left={c.left} $width={c.width}>{c.title}</Th>
                    ))}
                </Tr>
            </Thead>
            <Tbody>
                {data.map((item, index) => (
                    <Tr key={index}>
                        {item.map((i, idx) => (
                            <Td key={i.key + '_' + idx}>{i.value}</Td>
                        ))}
                    </Tr>
                ))}
            </Tbody>
        </Table>
    )
}

