import styled from "styled-components";

export const TableMiniContainer = styled.div`
    width: 100%;
    @media (min-width: 768px) {
        max-width: 440px;
    }
`;

export const ScoreLink = styled.a`
    color: #133D5E;
    text-decoration: none;
    padding-top: 4px;
    font-size: 18px;
    &:hover {
        text-decoration: underline;
    }
`;

interface MatchResultMarkerProps {
    $color: string;
}

export const MatchResultMarker = styled.div<MatchResultMarkerProps>`
    width: 14px;
    height: 14px;
    border-radius: 3px;
    margin-left: 6px;
    background-color: ${({ $color }) => $color};
`;

export const MatchResultContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const IconLink = styled.a`
    color: #133D5E;
    text-decoration: none;
    &:hover {
        color: blue;
    }
`;

export const LegendContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 8px;
    font-family: 'Bebas Neue';
`;

export const LegendItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
`;

export const LegendMarker = styled(MatchResultMarker)`
    margin-left: 0;
    margin-right: 6px;
`;

export const LegendText = styled.div`
    padding-top: 4px;
`;

export const MatchTypeIcon = styled.div`
    width: 24px;
    height: 24px;
    position: absolute;
    top: calc(50% - 8px);
    right: 6px;
`;

export const TableDateContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

export const TicketIcon = styled.img`
    width: 16px;
    margin-right: 4px;
`;