import { BACKEND_URL, BASE_URL } from "..";
import { FreeTicketPromoConfig } from "../../types/promo";

const GET_TICKETS_PROMO_CONFIG_URL = BASE_URL + "/api/promo/free-ticket/get_config.php";
const SEND_TICKETS_PROMO_FORM_URL = BASE_URL + "/api/promo/free-ticket/request_ticket.php";
const SEND_COSMOS_PROMO_FORM_URL = BASE_URL + "/api/promo/cosmos/uploadPhoto.php";

export const getTicketsPromoConfig = async (): Promise<FreeTicketPromoConfig> => {
    const response = await fetch(GET_TICKETS_PROMO_CONFIG_URL);
    const data = await response.json();
    return data as FreeTicketPromoConfig;
};

export const sendTicketsPromoForm = async (formData: FormData) => {
    const response = await fetch(SEND_TICKETS_PROMO_FORM_URL, {
        method: "POST",
        body: formData
    });
    const data = await response.json();
    return data;
};

export const getCosmosPhoto = async (id: string) => {
    const response = await fetch(BACKEND_URL + "/promos/promo/cosmos-2024/photo/" + id, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: +id }),
    });
    const data = await response.json();

    if (response.status !== 200) {
        throw new Error(data);
    }
    return data;
}

export const loginCosmosPromo = async (username: string, password: string) => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    const response = await fetch(BASE_URL + "/admin/api/cosmos/login.php", {
        method: "POST",
        body: formData,
    });
    const data = await response.json();
    if (response.status !== 200) {
        throw new Error(data);
    } else {
        return data;
    }
}

export const sendCosmosPromoForm = async (formData: FormData) => {
    const response = await fetch(SEND_COSMOS_PROMO_FORM_URL, {
        method: "POST",
        body: formData,
    });
    const data = await response.json();
    if (response.status !== 200) {
        throw new Error(data);
    } else {
        return data?.photo;
    }
};

export const getFileContent = async (url: string) => {
    const response = await fetch(url);
    const data = await response.blob();
    return data;
}