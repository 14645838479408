import styled from "styled-components";

interface ButtonContainerProps {
    $size: "small" | "medium" | "large";
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
    background-color: #133D5E;
    border: none;
    color: white;
    padding: ${({$size}) => {
        if ($size === "large") {
            return '16px 24px';
        } else if ($size === "medium") {
            return '8px 12px';
        } else {
            return '4px 6px';
        }
    }};
    text-align: center;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
    font-size: ${({$size}) => {
        if ($size === "large") {
            return '20px';
        } else if ($size === "medium") {
            return '16px';
        } else {
            return '14px';
        }
    }};
    cursor: pointer;
    &:hover {
        //opacity: 0.8;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    }
`;

export const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;