import styled from "styled-components";
import { Button as AntButton } from "antd";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface ContentProps {
    $open: boolean;
    $height?: number;
}

export const Content = styled.div<ContentProps>`
    height: ${({ $open, $height }) => $open ? 'auto' : ($height ? `${$height}px` : '0')};
    overflow: hidden;
`;

export const Hr = styled.hr`
    width: 100%;
    margin: 0;
    border: 0;
    opacity: 0.2;
    border-top: 1px solid #133D5E;
`;

export const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const Button = styled(AntButton)`
    font-size: 16px;
    font-family: 'Bebas Neue';
    font-weight: 600;
    color: #133D5E;
`;