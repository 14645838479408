import { MatchResult } from "../../types/stats";

export const getMatchResultColor = (result?: MatchResult) => {
    switch (result) {
        case MatchResult.WIN:
            return '#0070B4';
        case MatchResult.DRAW:
            return '#999';
        case MatchResult.LOSS:
            return '#F44336';
        default:
            return '#c1c1c1';
    }
}