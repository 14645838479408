import React, { FC, ReactElement } from 'react';
import ReactDOM from 'react-dom/client';

export const ReactAppRenderer = (App: FC, target: string): void | ReactElement => {
    let element = document.getElementById(target);
    if (!element) {
        return;
    }

    const reactApp = ReactDOM.createRoot(element);
    reactApp.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}