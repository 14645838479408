import { useEffect, useState } from "react";
import { MatchListDto, MatchStatus } from "../../types/stats";
import { MatchResultContainer, MatchResultMarker, MatchTypeIcon, ScoreLink, TableMiniContainer } from "./styled";
import { FCRodinaTable } from "../Table";
import { TeamTitle } from "../TeamTitle";
import { getMatchList } from "../../api/transport/statsTransport";
import { getMatchResultColor } from "./utils";
import { BuyTicketButton } from "./BuyTicket";
import { CarOutlined, HomeOutlined } from "@ant-design/icons";
import { TeamContext } from "../../types/common";

interface MatchListMiniProps {
    tournamentId: number;
    seasonId: number;
    teamId?: number;
    prevMatches?: number;
    totalMatches?: number;
    teamName?: TeamContext;
}

export const MatchListMini: React.FC<MatchListMiniProps> = ({
    tournamentId,
    seasonId,
    teamId,
    prevMatches,
    totalMatches,
    teamName,
}) => {
    const [mathcList, setMatchList] = useState<MatchListDto>([]);

    useEffect(() => {
        getMatchList(tournamentId, seasonId, teamId, teamName).then((data) => {
            let sliceStart = 0;
            let sliceEnd = data.length;

            if (sliceEnd > totalMatches) {
                const nearestMatchIndex = data.findIndex(
                    (item) => (new Date(item.date)).getTime() > Date.now());
                if (nearestMatchIndex === -1) {
                    sliceStart = Math.max(sliceEnd - totalMatches, 0);
                } else {
                    sliceStart = Math.max(nearestMatchIndex - prevMatches, 0);
                    sliceEnd = Math.min(sliceStart + totalMatches, sliceEnd);
                    if (sliceEnd - sliceStart < totalMatches) {
                        sliceStart = Math.max(sliceEnd - totalMatches, 0);
                    }
                }
            }

            const matchesToShow = data.slice(sliceStart, sliceEnd);

            setMatchList(matchesToShow);
        });
    }, [seasonId, tournamentId, teamId, totalMatches, prevMatches, teamName]);

    const dataColumns = [
        { title: 'Дата', dataIndex: 'date', key: 'date' },
        { title: 'Соперник', dataIndex: 'opponentTeamName', key: 'opponentTeamName' },
        { title: 'Счет', dataIndex: 'score', key: 'score' },
    ];

    const dataItems = mathcList.map((item) => {
        return dataColumns.map((column) => {
            let value = item[column.dataIndex];
            if (column.dataIndex === 'opponentTeamName') {
                return {
                    key: column.key,
                    value: (
                        <>
                            <TeamTitle logoUrl={item.opponentTeamLogo} name={value} />
                            <MatchTypeIcon>
                                {item.isHome ? <HomeOutlined /> : <CarOutlined />}
                            </MatchTypeIcon>
                        </>
                    )
                }
            } else if (column.dataIndex === 'date') {
                return {
                    key: column.key,
                    value: new Date(value).toLocaleString('ru-RU', {
                        day: 'numeric',
                        month: 'long',
                        hour: '2-digit',
                        minute: '2-digit',
                    })
                }
            } else if (column.dataIndex === 'score') {
                if (item.status === MatchStatus.СANCELLED || item.status === MatchStatus.UPCOMING) {
                    if (item.tickets) {
                        value = (
                            <BuyTicketButton url={item.tickets} />
                        );
                    } else {
                        value = '-';
                    }
                } else {
                    value = (
                        <MatchResultContainer>
                            <ScoreLink href={item.protocolUrl}>
                                {item.score}

                            </ScoreLink>
                            <MatchResultMarker $color={getMatchResultColor(item.result)} />
                        </MatchResultContainer>
                    );
                }
            }
            return {
                key: column.key,
                value
            }
        });
    });

    return (
        <TableMiniContainer>
            <FCRodinaTable columns={dataColumns} data={dataItems} />
        </TableMiniContainer>
    );
}

