import { useEffect, useState } from "react";
import {
    CloseButton,
    Wrapper,
    Content,
    ButtonContainer,
    MatchTitle,
    Popup,
    TicketForm,
    Title,
    Container,
    SubmitButton,
    BgImage,
    PreviewImage,
    PreviewImageContainer,
    ActionRules,
} from "./styled";
import { DeleteOutlined } from '@ant-design/icons';
import { Form, Upload, Result, Button, Collapse, message, Spin } from 'antd';
import { FreeTicketPromoConfig } from "../../types/promo";
import { validatePhoneNumber } from "./helpers";
import { FormInput } from "../../components/forms/styled";
import { UploadContent } from "../../components/forms/Upload";
import { FileType } from "../../types/common";
import { getBase64, validateImageSize } from "../../utils";
import { getTicketsPromoConfig, sendTicketsPromoForm } from "../../api/transport/promoTransport";
import { SocialLinksList } from "../../widgets/social/SocialLinks";

const { Dragger } = Upload;

interface FreeTicketPromoProps {
    children?: React.ReactNode;
}

export const FreeTicketPromo: React.FC<FreeTicketPromoProps> = ({ children }) => {
    const [loaded, setLoaded] = useState(false);
    const [promoConfig, setPromoConfig] = useState<FreeTicketPromoConfig>();
    const [formSent, setFormSent] = useState(false);
    const [formSending, setFormSending] = useState(false);
    const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);
    const [previewProcessing, setPreviewProcessing] = useState(false);
    const [sentEmail, setSentEmail] = useState<string>('');

    useEffect(() => {
        getTicketsPromoConfig().then(data => {
            setPromoConfig(data);
            setLoaded(!!data?.activated);
        });
    }, []);

    if (!loaded) {
        return null;
    }

    const matchTitle = promoConfig?.title || '';

    const onFormFinish = (values: any) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('phone', values.phone);
        formData.append('email', values.email);
        formData.append('image', values.upload[0].originFileObj);
        setFormSending(true);
        sendTicketsPromoForm(formData).then(data => {
            setFormSent(true);
            setSentEmail(values.email);
            setFormSending(false);
        }).catch((error) => {
            console.error(error);
            message.error('Ошибка отправки заявки! Пожалуйста, попробуйте повторить позже.');
            setFormSending(false);
        });
    }

    // Image file validator for Upload component
    const imageFileValidator = (file: FileType, ) => {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
            console.error('Please upload only image files!');
            return false;
        }

        if (!validateImageSize(file)) {
            console.error('File size is too big or zero');
            message.error('Файл должен быть изображением и не превышать 20 мб');
            return false;
        }

        setPreviewProcessing(true);
        getBase64(file).then((url) => {
            setPreviewImage(url as string);
            setPreviewProcessing(false);
        });

        return false;
    };

    let content = null;
    const closeWindow = () => {
        setLoaded(false);
    }

    const onDeleteUpload = () => {
        setPreviewImage(undefined);
    }

    if (formSent) {
        content = (
            <Result
                status="success"
                title={<>Спасибо!<br />Отправим билет на <br />{sentEmail || "указанную почту"}</>}
                subTitle={<>Подписывайся на нас в соцсетях<br /><SocialLinksList/></>}
                extra={[
                    <ButtonContainer>
                        <SubmitButton size="large" onClick={closeWindow}>
                            Хорошо
                        </SubmitButton>
                    </ButtonContainer>
                ]}
            />
        );
    } else {
        content = (
            <>
                <Title>
                    Заполни форму и получи бесплатный билет на матч
                </Title>
                <MatchTitle>{matchTitle}</MatchTitle>
                <TicketForm onFinish={onFormFinish}>
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: 'Пожалуйста, введите имя!' }]}
                    >
                        <FormInput placeholder="Имя" size="large" />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        rules={
                            [{
                                required: true,
                                message: 'Пожалуйста, введите номер телефона!',
                            }, {
                                validator: (rule, value) => {
                                    if (value && !validatePhoneNumber(value)) {
                                        return Promise.reject('Пожалуйста, введите корректный номер телефона!');
                                    }
                                    return Promise.resolve();
                                }
                            },
                            ]}
                    >
                        <FormInput placeholder="Номер телефона" size="large" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Пожалуйста, введите e-mail!' }]}
                    >
                        <FormInput placeholder="E-mail" size="large" />
                    </Form.Item>
                    <Form.Item
                        name="upload"
                        valuePropName="fileList"
                        hidden={!!previewImage}
                        getValueFromEvent={(e) => e.fileList}
                        rules={[{
                            required: true,
                            message: 'Пожалуйста, загрузите фото чека!',
                        }, {
                            validator: (rule, value) => {
                                if (
                                    !value ||
                                    (!previewImage && !previewProcessing) ||
                                    value.length === 0 ||
                                    !value[0].originFileObj
                                ) {
                                    console.error('No file!');
                                    return Promise.reject('Пожалуйста, загрузите фото чека!');
                                } else if (!validateImageSize(value[0].originFileObj)) {
                                    console.error('Wrong file!');
                                    return Promise.reject('Файл должен быть изображением и не превышать 2 мб!');
                                }
                                return Promise.resolve();
                            }
                        }]}
                    >
                        <Dragger
                            name="file"
                            accept="image/*"
                            beforeUpload={imageFileValidator}
                            showUploadList={false}
                            maxCount={1}
                            style={{ display: previewImage ? 'none' : 'flex' }}
                        >
                            {!previewImage && (
                                <UploadContent
                                    text="Нажмите или перетяните фото чека покупки в Fix-Price в эту область"
                                    hint="На фото должен быть виден номер чека и дата покупки"
                                />
                            )}
                        </Dragger>
                    </Form.Item>
                    {previewImage && (
                        <PreviewImageContainer>
                            <PreviewImage src={previewImage} alt="Preview" preview={true} />
                            <Button danger type="link" icon={<DeleteOutlined />} onClick={onDeleteUpload}>
                                Удалить фото
                            </Button>
                        </PreviewImageContainer>)
                    }
                    <Form.Item>
                        <ButtonContainer>
                            {!formSending && <SubmitButton size="large">
                                Получить бесплатный билет
                            </SubmitButton>}
                            {formSending && <Spin size="large" />}
                        </ButtonContainer>
                    </Form.Item>
                    <Collapse
                        ghost
                        items={[{
                            key: 'action-rules',
                            label: 'Условия акции',
                            children: (
                                <ActionRules>
                                    <p>1. Отсканировать QR-код с рекламного ролика ФК "Родина", который транслируется на видео-панелях в сети Fix-Price</p>
                                    <p>2. Совершить покупку в магазине Fix-Price на любую сумму</p>
                                    <p>3. Загрузить фото чека по форме выше</p>
                                    <p>4. На один чек предоставляется один бесплатный билет</p>
                                    <p>5. Дата покупки должна быть совпадать с днем отправки формы выше</p>
                                    <p>6. Количество чеков неограничено</p>
                                </ActionRules>
                            )
                        }]}
                    />
                </TicketForm>
            </>
        );
    }

    return (
        <Wrapper>
            <Container>
                <Popup>
                    <BgImage src={promoConfig?.image} />
                    {formSent && <CloseButton onClick={closeWindow}>×</CloseButton>}
                    <Content>
                        {content}
                    </Content>
                </Popup>
            </Container>
        </Wrapper>
    );
}