import { FileType } from "../types/common";

export const extractTextFromHtml = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
}

export const extractFirstParagraphFromHtml = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    let firstParagraph = doc.querySelector('p');
    let textContent = firstParagraph?.textContent ?? '';

    if (!textContent) {
        firstParagraph = doc.querySelector('div');
        textContent = firstParagraph?.textContent ?? '';
    }

    return textContent;
}

export const extractParagraphsFromHtml = (html: string, divsCount: number = 1) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');

    let divs = doc.querySelectorAll('body > div');

    if (!divs.length) {
        divs = doc.querySelectorAll('body > p');
    }

    let notEmptyDivsCount = 0;

    const res = document.createElement('div');

    divs.forEach((div) => {
        if (notEmptyDivsCount >= divsCount) {
            return;
        }

        res.appendChild(div);

        const textContent = div.textContent.trim();

        if (textContent) {
            notEmptyDivsCount++;
        }
    });

    return res.outerHTML;
}

export const getBase64 = (img: FileType) => {
    const res = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            resolve(reader.result);
        });
        reader.readAsDataURL(img);
    });

    return res;
};

export const validateImageSize = (file: FileType) => {
    if (
        !file.type.startsWith('image/') ||
        file.size > (1024 * 1024 * 20) ||
        file.size === 0
    ) {
        return false;
    }
    return true;
};

export const converUrlToBase64 = (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            resolve(dataURL);
        };
        img.src = url;
    });
}

export const getBase64Url = (base64: string) => {
    return `data:image/png;base64,${base64}`;
}

export const base64ImageToBlob = (str): Blob => {
    // extract content type and base64 payload from original string
    var pos = str.indexOf(';base64,');
    var type = str.substring(5, pos);
    var b64 = str.substr(pos + 8);

    // decode base64
    var imageContent = atob(b64);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    var buffer = new ArrayBuffer(imageContent.length);
    var view = new Uint8Array(buffer);

    // fill the view, using the decoded base64
    for (var n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n);
    }

    // convert ArrayBuffer to Blob
    var blob = new Blob([buffer], { type: type });

    return blob;
}