import { GetProp, UploadProps } from "antd";
export type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export enum EntityType {
    PLAYER = 'player',
    TEAM_PLAYER = 'teamPlayer',
    NEWS = 'news',
}

export enum TeamContext {
    RODINA = 'rodina',
    ACADEMY = 'academy',
    RODINA_2 = 'rodina-2',
    RODINA_M = 'rodina-m',
}
