import { useEffect, useState } from "react";
import { Collapse } from "../../Collapse";
import { BioContainer, Container, Content, Summary, Title } from "./styled";
import { PlayerDto } from "../../../types/player";
import { getPlayerInfo } from "../../../api";
import { extractParagraphsFromHtml } from "../../../utils";

export const PlayerBio = () => {
    const [playerData, setPlayerData] = useState<PlayerDto | null>(null);
    const [loading, setLoading] = useState(true);
    const [summary, setSummary] = useState<string>('');
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

     useEffect(() => {
        const path = window.location.pathname;
        const sections = path.split('/');
        let playerId = sections.pop();
        if (!playerId) {
            playerId = sections.pop();
        }

        if (!playerId) {
            return;
        }

        setLoading(true);
        getPlayerInfo(playerId).then((data: PlayerDto) => {
            setPlayerData(data);
            if (data?.bio?.length) {
                setSummary(extractParagraphsFromHtml(data?.bio, 2));
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return null;
    }

    if (!playerData?.bio) {
        return null;
    }

    return (
        <Container>
            <Title>Биография</Title>
            <Content>
                {isCollapsed && summary && <Summary
                    dangerouslySetInnerHTML={{ __html: summary }}
                />}
                <Collapse height={0} onStateChange={(open)=>setIsCollapsed(!open)}>
                    <BioContainer
                        dangerouslySetInnerHTML={{ __html: playerData?.bio }}
                    >
                    </BioContainer>
                </Collapse>
            </Content>
        </Container>
    );
}
