import React, { useEffect, useMemo, useRef, useState } from "react";
import { Carousel as AntdCarousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
    ButtonsContainer,
    Slide,
    Container,
    SlideWrapper,
    LastElementWrapper,
    PrevButton,
    NextButton
} from "./styled";
import { CarouselRef } from "antd/es/carousel";

interface CarouselProps {
    children: React.ReactNode;
    elementWidth: number;
    onChange?: (currentSlide: number) => void;
}

const BUTTONS_OFFSET = 50;

export const Carousel: React.FC<CarouselProps> = ({ children, elementWidth, onChange }) => {
    const carouselRef = useRef<CarouselRef>(null);
    const [slideWidth, setSlideWidth] = useState(0);
    const [cardsBySlide, setCardsBySlide] = useState(1);
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = useMemo(() => {
        const elements = React.Children.toArray(children);
        let currentSlide: React.ReactNode[] = [];
        const res: React.ReactNode[] = [];
        elements?.forEach((child: React.ReactNode, index: number, array: React.ReactNode[]) => {
            currentSlide.push(child);
            const lastInSlide = (index + 1) % cardsBySlide === 0;
            const lastElement = index === array.length - 1;
            if (lastInSlide || lastElement) {
                if (!lastElement) {
                    currentSlide.push(
                        <LastElementWrapper key={index}>
                            {array[index + 1]}
                        </LastElementWrapper>
                    );
                } else {
                    if (currentSlide.length < cardsBySlide && array.length > cardsBySlide) {
                        const len = currentSlide.length;
                        const diff = cardsBySlide - currentSlide.length;
                        for (let i = 0; i < diff; i++) {
                            currentSlide.unshift(array[index - i - len]);
                        }
                    }
                }
                res.push(
                    <SlideWrapper key={`slide_${index}`}>
                        <Slide $fullWidth={lastInSlide}>
                            {currentSlide}
                        </Slide>
                    </SlideWrapper>
                );
                currentSlide = [];
            }
        });
        return res;
    }, [children, cardsBySlide]);

    useEffect(() => {
        if (carouselRef.current) {
            const width = carouselRef.current?.innerSlider?.list?.clientWidth;
            const count = Math.floor(width / elementWidth);
            if (count !== cardsBySlide) {
                setCardsBySlide(count);
                setCurrentSlide(0);
                setSlideWidth(width);
            }
        }
    }, [cardsBySlide, carouselRef?.current?.innerSlider?.list, elementWidth]);

    const onChangeHandler = (currentSlide: number) => {
        if (onChange) {
            onChange(currentSlide);
        }
    }

    useEffect(() => {
        const handleResize = () => {
            if (carouselRef.current) {
                const width = carouselRef.current?.innerSlider?.list?.clientWidth;
                const count = Math.floor(width / elementWidth);
                if (count !== cardsBySlide) {
                    setCardsBySlide(count);
                    setCurrentSlide(0);
                    setSlideWidth(width);
                }
            }
        };

        window.addEventListener('resize', handleResize);
        // Cleanup: remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [cardsBySlide, elementWidth]);

    const beforeChangeHandler = (from: number, to: number) => {
        setCurrentSlide(to);
    }

    const cardsWidth = cardsBySlide * elementWidth;
    const buttonsRight = cardsWidth - ((cardsWidth >= slideWidth - BUTTONS_OFFSET) ? 2 * BUTTONS_OFFSET : BUTTONS_OFFSET);

    const hasButtons = slides.length > 1;

    return (
        <Container>
            {hasButtons && <ButtonsContainer $left={buttonsRight - 8}>
                <PrevButton
                    disabled={currentSlide <= 0}
                    onClick={() => carouselRef?.current?.prev()}
                >
                    <LeftOutlined />
                </PrevButton>
                <NextButton
                    disabled={currentSlide >= slides.length - 1}
                    onClick={() => carouselRef?.current?.next()}
                >
                    <RightOutlined />
                </NextButton>
            </ButtonsContainer>}
            <AntdCarousel ref={carouselRef} afterChange={onChangeHandler} effect="fade" beforeChange={beforeChangeHandler}>
                {slides}
            </AntdCarousel>
        </Container>
    );
}