import { Carousel } from "../../Carousel";
import { Container, Title } from "./styled";

interface NewsListProps {
    children: React.ReactNode;
    title?: string;
}

export const NewsList: React.FC<NewsListProps> = ({ children, title }) => {
    const onChange = (currentSlide: number) => {
        console.log(currentSlide);
    };
    return (
        <Container>
            {title && <Title>{title}</Title>}
            <Carousel onChange={onChange} elementWidth={288}>
                {children}
            </Carousel>
        </Container>
    );
}
