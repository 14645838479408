import { EntityType, TeamContext } from "../types/common";
import { News } from "../types/news";
import { SocialLinks } from "../types/promo";

export const BASE_URL = process.env.REACT_APP_PHP_BASE_URL;
const GET_SOCIAL_LINKS_URL = BASE_URL + "/api/get_social_links.php";

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const GET_PLAYER_NEWS_URL = `${BACKEND_URL}/news/search`
const GET_PLAYER_INFO_URL =  `${BACKEND_URL}/legacy/rodina/players`

export const getSocialLinks = async (): Promise<SocialLinks> => {
    const response = await fetch(GET_SOCIAL_LINKS_URL);
    const data = await response.json();
    return data as SocialLinks;
};

interface GetNewsParams {
    playerId: string;
}
export const getPlayerNews = async ({ playerId }: GetNewsParams) => {
    const response = await fetch(GET_PLAYER_NEWS_URL, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            entityType: EntityType.PLAYER,
            entityId: playerId
        })
    });
    const data = await response.json();
    return data as News[];
}

export const getPlayerInfo = async (playerId: string) => {
    const response = await fetch(`${GET_PLAYER_INFO_URL}/${playerId}`);
    const data = await response.json();
    return data;
}

export const buildApiUrl = (url: string, context?: TeamContext) => {
    let ctx = context?.trim();
    if (ctx) {
        return `/${ctx}${url}`;
    }
    return url;
}