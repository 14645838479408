import styled from "styled-components";

export const Container = styled.div`
    background-color: #fff;
    width: 100%;
    padding: 20px 24px;
    max-width: 965px;
    box-sizing: border-box;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
`;

export const Title = styled.div`
    font-size: 24px;
    font-family: 'Bebas Neue';
    color: #000;
    margin: 10px;
`;