import { Card as AntCard } from "antd";
import styled from "styled-components";

export const Card = styled(AntCard)`
    position: relative;
    width: 270px;
    min-width: 270px;
    height: 400px;
    max-height: 400px;
    margin: 10px;
    border-radius: 3px;
    font-family: 'Bebas Neue';
    overflow: hidden;
    &:hover {
        ${({hoverable})=> hoverable ? 'box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);' : ''}
    }
`;

export const NewsInfo = styled.div`
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 24px;
    background-color: #fff;
    color: #888;
`;

export const NewsInfoItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-size: 16px;
    margin-right: 24px;
`;

export const NewsInfoItemText = styled.span`
    font-size: 16px;
    margin-left: 5px;
    margin-top: 4px;
`;

export const CardCoverImage = styled.img`
    border-radius: 3px 3px 0 0 !important;
    scale: 1.05;
    max-height: 135px;
    height: 135px;
    object-fit: cover;
    object-position: top center;
    transition: all 0.3s ease;
`;

export const Content = styled.div`
`;