import { Form, Image } from "antd";
import styled from "styled-components";
import { FCRodinaButton } from "../../components/Button";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 64px;
    box-sizing: border-box;
    overflow: auto;
    background-image: url(https://fcrodina.com/i/background_new.png);

    @media (max-width: 500px) {
        padding: 0;
        height: 100vh;
    }
`;

export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 32px;
    border-radius: 8px;
    @media (max-width: 500px) {
        padding: 8px;
        border-radius: 0;
        height: 100%;
    }
`;

export const PhotoNumber = styled.h1`
    font-size: 96px;
    margin-bottom: 32px;
`;

export const Title = styled.h2`
    font-size: 24px;
    margin-bottom: 0;
    max-width: 400px;
    text-align: center;
    color: #133D5E !important;
`;

export const CosmosUploadForm = styled(Form)`
    width: 100%;
    max-width: 800px;
    @media (max-width: 500px) {
        border-radius: 0;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
`;

export const SubmitButton = styled(FCRodinaButton)`
    margin-top: 16px;
    min-height: 50px;

    @media (max-width: 500px) {
        min-width: 100%;
        margin: 0;
    }
`;

export const PreviewImage = styled(Image)`
    max-width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
`;

export const PreviewImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   // margin-top: 16px;
    margin-bottom: 24px;
`;
