import styled from "styled-components";

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 0;
    font-family: 'Bebas Neue';
    font-size: 16px;
`;

export const TableMiniContainer = styled.div`
    max-width: 440px;
`;

export const Thead = styled.thead`
    background-color: #dbf1ff;
    border: 0;
`;

interface ThProps {
    $left?: boolean;
    $width?: number;
}

export const Th = styled.th<ThProps>`
    padding: 14px 10px 10px;
    color: #555;
    border: 0;
    text-align: ${(props) => props.$left ? 'left' : 'center'};
    padding-left: ${(props) => props.$left ? '24' : '10'}px;
    width: ${(props) => props.$width ? props.$width + 'px' : 'none'};
    font-size: 14px;
    &:not(:last-child) {
        border-right: 1px solid #afd7f0;
    }
`;

export const TeamLogo = styled.img`
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-right: 12px;
`;

export const TeamNameContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: -4px;
    padding-left: 16px;
`;

export const TextWrapper = styled.div`
    display: inline-block;
    padding-top: 4px;
`;

export const Tbody = styled.tbody`
    background-color: #fff;
`;

export const Td = styled.td`
    padding: 14px 5px 10px 5px;
    border: 0;
    text-align: center;
    vertical-align: middle;
    position: relative;
    &:not(:last-child) {
        border-right: 1px solid #afd7f0;
    }
`;

interface TrProps {
    $highlight?: boolean;
}

export const Tr = styled.tr<TrProps>`
    border: 0;
    font-weight: ${(props) => props.$highlight ? 'bold' : 'normal'};
    position: relative;
    &:nth-child(even) {
        background-color: #f2f2f2;
    }
`;