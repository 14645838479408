export const validatePhoneNumber = (phoneNumber: string): boolean => {
    // Remove non-numeric characters
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

    // Check if the numeric phone number has a valid length
    if (numericPhoneNumber.length >= 10 && numericPhoneNumber.length <= 15) {
        // You can add more specific validation rules based on your needs
        return true;
    } else {
        return false;
    }
}