import { useEffect, useState } from "react";
import { TournamentTableDto } from "../../types/stats";
import { getTournamentTable } from "../../api/transport/statsTransport";
import { FCRodinaTable } from "../Table";
import { TeamTitle } from "../TeamTitle";
import { TeamContext } from "../../types/common";

interface TournamentTableProps {
    tournamentId: number;
    seasonId: number;
    teamName?: TeamContext;
}

export const TournamentTable: React.FC<TournamentTableProps> = ({tournamentId, seasonId, teamName}) => {
    const [tournamentTable, setTournamentTable] = useState<TournamentTableDto>([]);

    useEffect(() => {
        getTournamentTable(tournamentId, seasonId, teamName).then((data) => {
            setTournamentTable(data);
        });
    }, [seasonId, teamName, tournamentId]);

    const dataColumns = [
        { title: 'Место', dataIndex: 'position', key: 'position' },
        { title: 'Команда', dataIndex: 'teamName', key: 'teamName', left: true },
        { title: 'И', dataIndex: 'matchesPlayed', key: 'matchesPlayed' },
        { title: 'В', dataIndex: 'wins', key: 'wins' },
        { title: 'Н', dataIndex: 'draws', key: 'draws' },
        { title: 'П', dataIndex: 'losses', key: 'losses' },
        { title: 'ГЗ', dataIndex: 'goalsFor', key: 'goalsFor' },
        { title: 'ГП', dataIndex: 'goalsAgainst', key: 'goalsAgainst' },
        { title: 'О', dataIndex: 'points', key: 'points' }
    ];

    const dataItems = tournamentTable.map((item) => {
        return dataColumns.map((column) => {
            const value = item[column.dataIndex];
            if (column.dataIndex === 'teamName') {
                return {
                    key: column.key,
                    value: (
                        <TeamTitle logoUrl={item.teamLogo} name={value}/>
                    )
                }
            }
            return {
                key: column.key,
                value
            }
        });
    });

    return (
        <FCRodinaTable
            columns={dataColumns}
            data={dataItems}
        />
    );
}

