import styled from "styled-components";

export const SocialLinksContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
`;

export const SocialLink = styled.a`
    margin: 0 8px;
    transition: 0.3s;
    opacity: 0.8;
    color: #133D5E;
    &:hover {
        opacity: 1;
        transform: scale(1.05);
    }
`;

export const SocialIcon = styled.img`
    width: 48px;
    width: 48px;
    cursor: pointer;
`;
