import reportWebVitals from './reportWebVitals';
import {
    ContactUsButtonApp,
    CosmosPromoLoginPageApp,
    CosmosPromoPageApp,
    CosmosPromoUploaderPageApp,
    MainScreenStatsApp,
    MatchListTableApp,
    PlayerBioApp,
    PlayerNewsApp,
    TournamentTableApp
} from './apps';
import { FreeTicketPromo } from './pages/FreeTicketPromo';
import LauncherPage from './pages/launcher/page';
import { ReactAppRenderer } from './utils/reactApp';
import './index.css';

ReactAppRenderer(ContactUsButtonApp, 'fcrc__contactUsButton');
ReactAppRenderer(FreeTicketPromo, 'fcrc__freeTicketPromo');
ReactAppRenderer(LauncherPage, 'fcrc__launcher');
ReactAppRenderer(PlayerNewsApp, 'fcrc__playerNews');
ReactAppRenderer(PlayerBioApp, 'fcrc__playerBio');
ReactAppRenderer(TournamentTableApp, 'fcrc__tournamentTable');
ReactAppRenderer(MainScreenStatsApp, 'fcrc__mainScreenStats');
ReactAppRenderer(MatchListTableApp, 'fcrc__matchList');
ReactAppRenderer(CosmosPromoPageApp, 'fcrc__cosmosPromo');
ReactAppRenderer(CosmosPromoUploaderPageApp, 'fcrc__cosmosPromoUploader');
ReactAppRenderer(CosmosPromoLoginPageApp, 'fcrc__cosmosPromoLogin');


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
