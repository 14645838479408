import { ButtonContainer, ButtonContent } from "./styled";

interface FCRodinaButtonProps {
    children: React.ReactNode;
    onClick?: () => void;
    style?: React.CSSProperties;
    className?: string;
    size?: "small" | "medium" | "large";
}

export const FCRodinaButton: React.FC<FCRodinaButtonProps> = ({children, onClick, style, size = "medium"}) => {
    return (
        <ButtonContainer onClick={onClick} style={{...style}} $size={size}>
            <ButtonContent>
                {children}
            </ButtonContent>
        </ButtonContainer>
    );
}