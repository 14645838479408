import { Form } from "antd";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    padding: 64px;
    box-sizing: border-box;
    overflow: auto;
    background-image: url(https://fcrodina.com/i/background_new.png);
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
        padding: 0;
        overflow: hidden;
    }
`;

export const LoginForm = styled(Form)`
    background-color: white;
    padding: 32px 64px 24px;
    max-width: 360px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 500px) {
        border-radius: 0;
        width: 100%;
    }
`;

export const Logo = styled.img`
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-right: 8px;
`;

export const Title = styled.h1`
    font-size: 26px;
    font-weight: bold;
    color: #133D5E;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
`;