import { useEffect, useState } from "react";
import { NewsCard } from "../NewsCard";
import { NewsList } from "../NewsList";
import { getPlayerNews } from "../../../api";
import { Spin } from "antd";
import { extractTextFromHtml } from "../../../utils";

export const PlayerNews = () => {
    const [playerNews, setPlayerNews] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const path = window.location.pathname;
        const sections = path.split('/');
        let playerId = sections.pop();
        if (!playerId) {
            playerId = sections.pop();
        }

        if (!playerId) {
            return;
        }

        setLoading(true);
        getPlayerNews({ playerId }).then((news) => {
            setPlayerNews(news);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }, []);

    if (!playerNews?.length && !loading) {
        return null;
    }

    if (loading) {
        return <Spin />;
    }

    return (
        <NewsList title="МАТЕРИАЛЫ">
            {playerNews?.map((news) => {
                const content = extractTextFromHtml(news.description).split('.')[0];
                return (
                    <NewsCard
                        key={news.id}
                        title={news.title}
                        content={content}
                        cover={news.image}
                        date={news.date}
                        category={news.category}
                        url={news.url}
                    />
                )
            }
            )}
        </NewsList>
    );
}