import { LauncherPageContainer } from "./styled";

export const LauncherPage: React.FC = () => {
    return (
        <LauncherPageContainer>
            <video
                id="movie"
                width="100%"
                style={{opacity: 0.7}}
                loop
                autoPlay
                muted
            >
                <source src="https://fcrodina.com/static/video/launcher.mp4" />
            </video>
        </LauncherPageContainer>
    );
}
export default LauncherPage;