import styled from "styled-components";

export const MainScreenStatsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
    width: 100%;

    @media (min-width: 768px) {
        justify-content: space-between;
        flex-direction: row;
    }
`;

export const ContentItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    @media (min-width: 768px) {
        width: calc(50% - 8px);
        margin-bottom: 0;
    }
`;

export const ContentItemTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: center;
    width: 100%;
`;

export const LinksContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 24px;
    align-items: center;

    @media (min-width: 500px) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        max-width: 760px;
    }
`;

export const StatLink = styled.a`
    font-size: 16px;
    font-weight: 500;
    color: #133D5E;
    text-decoration: none;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-bottom: 8px;

    &:hover {
        color: #007bff;
    }
`;