import { FC } from "react";
import { TeamLogo, TeamNameContainer, TextWrapper } from "./styled";

interface TeamTitleProps {
    logoUrl: string;
    name: string;
}

export const TeamTitle: FC<TeamTitleProps> = ({logoUrl, name}) => {
    return (
        <TeamNameContainer>
            <TeamLogo src={logoUrl} />
            <TextWrapper>{name}</TextWrapper>
        </TeamNameContainer>
    );
}