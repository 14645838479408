import { useEffect, useState } from "react";
import { TournamentTableDto } from "../../types/stats";
import { getTournamentTable } from "../../api/transport/statsTransport";
import { TableMiniContainer } from "./styled";
import { FCRodinaTable } from "../Table";
import { TeamTitle } from "../TeamTitle";
import { TeamContext } from "../../types/common";

interface TournamentTableProps {
    tournamentId: number;
    seasonId: number;
    teamId?: number;
    topTeams?: number;
    bottomTeams?: number;
    teamName?: TeamContext;
}

export const TournamentTableMini: React.FC<TournamentTableProps> = ({
    tournamentId,
    seasonId,
    teamId,
    topTeams,
    bottomTeams,
    teamName
}) => {
    const [tournamentTable, setTournamentTable] = useState<TournamentTableDto>([]);

    useEffect(() => {
        getTournamentTable(tournamentId, seasonId, teamName).then((data) => {
            const res = [];

            let topCount = topTeams || 3;
            let bottomCount = bottomTeams || 2;
            let needInsertion = false;

            const teamIndex = data.findIndex((item) => item.teamId === teamId);

            if (teamIndex < 0) {
                return;
            }

            if (teamIndex < topCount - 1 || teamIndex >= data.length - bottomCount) {
                bottomCount++;
            } else if (teamIndex === topCount - 1) {
                topCount++;
            } else {
                needInsertion = true;
            }

            for (let i = 0; i < topCount; i++) {
                res.push(data[i]);
            }

            if (needInsertion) {
                res.push(data[teamIndex]);
            }

            for (let i = data.length - bottomCount; i < data.length; i++) {
                res.push(data[i]);
            }

            setTournamentTable(res);
        });
    }, [bottomTeams, seasonId, teamId, teamName, topTeams, tournamentId]);

    const dataColumns = [
        { title: 'Место', dataIndex: 'position', key: 'position' },
        { title: 'Команда', dataIndex: 'teamName', key: 'teamName', left: true },
        { title: 'И', dataIndex: 'matchesPlayed', key: 'matchesPlayed' },
        { title: 'О', dataIndex: 'points', key: 'points' }
    ];

    const dataItems = tournamentTable?.map((item) => {
        return dataColumns.map((column) => {
            const value = item[column.dataIndex];
            if (column.dataIndex === 'teamName') {
                return {
                    key: column.key,
                    value: (
                        <TeamTitle logoUrl={item.teamLogo} name={value}/>
                    )
                }
            }
            return {
                key: column.key,
                value
            }
        });
    });

    return (
        <TableMiniContainer>
            <FCRodinaTable
                columns={dataColumns}
                data={dataItems}
            />
        </TableMiniContainer>
    );
}

