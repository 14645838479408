import { FC } from "react";
import {
    Content,
    ContentItem,
    ContentItemTitle,
    LinksContainer,
    MainScreenStatsContainer,
    StatLink
} from "./styled";
import { MatchListMini } from "../../../components/MatchListTable/MatchListMini";
import { TournamentTableMini } from "../../../components/TournamentTable/TableMini";
import { TeamContext } from "../../../types/common";

interface MainScreenStatsProps {
    tournamentId: number;
    seasonId: number;
    teamId?: number;
    topTeams?: number;
    bottomTeams?: number;
    prevMatches?: number;
    teamName?: TeamContext;
    linksPath?: string;
}

export const MainScreenStats: FC<MainScreenStatsProps> = (
    { tournamentId, seasonId, teamId, topTeams, bottomTeams, prevMatches, teamName, linksPath = '' }
) => {
    return (
        <MainScreenStatsContainer>
            <Content>
                <ContentItem>
                    <ContentItemTitle>Турнирная таблица</ContentItemTitle>
                    <TournamentTableMini
                        tournamentId={tournamentId}
                        seasonId={seasonId}
                        teamId={teamId}
                        topTeams={topTeams}
                        bottomTeams={bottomTeams}
                        teamName={teamName}
                    />
                </ContentItem>
                <ContentItem>
                    <ContentItemTitle>Матчи</ContentItemTitle>
                    <MatchListMini
                        tournamentId={tournamentId}
                        seasonId={seasonId}
                        teamId={teamId}
                        prevMatches={prevMatches}
                        totalMatches={topTeams + bottomTeams + 1}
                        teamName={teamName}
                    />
                </ContentItem>
            </Content>
            <LinksContainer>
                <StatLink href={`${linksPath}tables/`}>Полная таблица</StatLink>
                <StatLink href={`${linksPath}games/stat/`}>Бомбардиры</StatLink>
                <StatLink href={`${linksPath}games/`}>Матчи</StatLink>
            </LinksContainer>
        </MainScreenStatsContainer>
    );
}