import styled from "styled-components";
import { Button, Image } from "antd";
import { FormInput } from "../../components/forms/styled";
import { FCRodinaButton } from "../../components/Button";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 64px;
    box-sizing: border-box;
    overflow: auto;
    background-image: url(https://fcrodina.com/i/background_new.png);
    overflow: hidden;
    color: #133D5E;
    @media (max-width: 500px) {
        padding: 0;
        margin: 0;
        width: 100vw;
        height: 100%;
        min-height: 100%;
        box-sizing: border-box;
        height: 100vh;
    }
`;

export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 32px;
    border-radius: 8px;
    max-width: 768px;
    min-height: 500px;
    @media (max-width: 500px) {
        padding: 24px 8px;
        border-radius: 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
    }
`;

export const Title = styled.h1`
    font-size: 32px;
    margin-bottom: 12px;
    text-align: center;
`;

export const SubTitle = styled.h2`
    font-size: 24px;
    margin-bottom: 0;
    max-width: 400px;
    text-align: center;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 0 48px;
    width: 100%;
`;

export const PreviewImage = styled(Image)`
    max-width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
`;

export const PreviewImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   // margin-top: 16px;
    margin-bottom: 24px;
`;

export const PhotoIdInput = styled(FormInput)`
    font-size: 24px;
    margin-bottom: 32px;
    input {
        text-align: center;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
`;

export const SubmitButton = styled(FCRodinaButton)`
    margin-top: 16px;
    min-height: 50px;

    @media (max-width: 500px) {
        min-width: 100%;
        margin: 0;
    }
`;

export const ShareContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    scale: 0.8;

    @media (max-width: 500px) {
        flex-direction: column;
    }
`;

export const ShareTitle = styled.h2`
    font-size: 24px;
    margin-bottom: 0;
    max-width: 400px;
    text-align: center;
    color: #133D5E !important;
    margin-top: 12px;
`;

export const LinkButton = styled(Button)`
    color: #133D5E;
`;