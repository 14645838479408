import { Button, Form, Input, message } from "antd";
import { Container, LoginForm, Logo, Title, TitleContainer } from "./styled";
import { loginCosmosPromo } from "../../api/transport/promoTransport";

export const CosmosPromoLoginPage = () => {
    const onFinish = (values: any) => {
        loginCosmosPromo(values.username, values.password).then(data => {
            window.location.reload();
        }).catch(() => {
            message.error('Неправильный логин или пароль!');
        });
    }

    return (
        <Container>
            <LoginForm
                name="login"
                autoComplete="off"
                layout='vertical'
                size='large'
                onFinish={onFinish}
            >
                <TitleContainer>
                    <Logo src="https://fcrodina.com/i/logo.png" alt="logo" />
                    <Title>
                        ФК Родина
                    </Title>
                </TitleContainer>
                <Form.Item
                    label="Имя пользователя"
                    name="username"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                    style={{ width: '100%' }}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                    style={{ width: '100%' }}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" size='large'>
                        Войти
                    </Button>
                </Form.Item>
            </LoginForm>
        </Container>
    );
}