import { ContactUsButton } from "../components/ContactUsButton";
import { FreeTicketPromo } from "../pages/FreeTicketPromo";
import { MainScreenStats } from "../widgets/mainScreen/MainScreenStats";
import { TournamentTable } from "../components/TournamentTable";
import { PlayerNews } from "../components/news/PlayerNews";
import { PlayerBio } from "../components/player/PlayerBio";
import { StatsFilter } from "../types/stats";
import { MatchListTable } from "../components/MatchListTable";
import { CosmosPromoPage } from "../pages/cosmosPromo/page";
import { CosmosPromoUploaderPage } from "../pages/cosmosPromoUploader/page";
import { TeamContext } from "../types/common";
import { CosmosPromoLoginPage } from "../pages/cosmosPromoLogin";

export const ContactUsButtonApp = () => {
    return (
        <ContactUsButton phoneNumber="+79032801727">
            Появился вопрос - спрашивай :)
        </ContactUsButton>
    );
};

export const FreeTicketPromoApp = () => {
    return (
        <FreeTicketPromo />
    );
};

export const PlayerNewsApp = () => {
    return (
        <PlayerNews />
    );
}

export const PlayerBioApp = () => {
    return (
        <PlayerBio />
    );
}

export const TournamentTableApp = () => {
    const statsFilter: StatsFilter = window.FCRC_PHP_DATA as StatsFilter;
    return (
        <TournamentTable
            tournamentId={statsFilter.tournamentId}
            seasonId={statsFilter.seasonId}
            teamName={statsFilter.teamName}
        />
    );
}

export const MatchListTableApp = () => {
    const statsFilter: StatsFilter = window.FCRC_PHP_DATA as StatsFilter;
    return (
        <MatchListTable
            tournamentId={statsFilter.tournamentId}
            seasonId={statsFilter.seasonId}
            teamId={statsFilter.teamId}
            teamName={statsFilter.teamName}
        />
    );
}

export const MainScreenStatsApp = () => {
    const statsFilter: StatsFilter = window.FCRC_PHP_DATA as StatsFilter;
    return (
        <MainScreenStats
            tournamentId={statsFilter.tournamentId}
            seasonId={statsFilter.seasonId}
            teamId={statsFilter.teamId}
            topTeams={statsFilter.mainScreenTableConfig?.topTeams || 3}
            bottomTeams={statsFilter.mainScreenTableConfig?.bottomTeams || 2}
            prevMatches={statsFilter.mainScreenMatchesConfig?.prevMatches || 2}
            teamName={statsFilter.teamName}
            linksPath={(statsFilter.teamName && statsFilter.teamName !== TeamContext.RODINA) ? '' : '/'}
        />
    );
}

export const CosmosPromoPageApp = () => {
    return (
       <CosmosPromoPage />
    );
}

export const CosmosPromoUploaderPageApp = () => {
    return (
       <CosmosPromoUploaderPage />
    );
}

export const CosmosPromoLoginPageApp = () => {
    return (
       <CosmosPromoLoginPage />
    );
}
