import { Form, Input, Image } from "antd";
import styled from "styled-components";
import { FCRodinaButton } from "../../components/Button";

export const Wrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 64px;
    box-sizing: border-box;
    overflow: auto;

    @media (max-width: 768px) {
        padding: 0;
        overflow: hidden;
    }
`;

export const Popup = styled.div`
    font-family: 'Roboto', sans-serif;
    color: #133D5E;
    background-color: white;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    max-width: 768px;
    width: 768px;
    position: relative;
    overflow: hidden;

    @media (max-width: 768px) {
        width: 100%;
        border-radius: 0;
        height: 100%;
        overflow: auto;
    }
`;

export const BgImage = styled.img`
    width: 100%;
    object-fit: cover;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 32px;
    color: #133D5E;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;

    &:hover {
        //border: 1px solid #133D5E;
        box-shadow: 0 0 10px 0 rgba(19, 61, 94, 0.2) inset;
        inset;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 64px;

    @media (max-width: 768px) {
        padding: 12px;
    }
`;

export const MatchTitle = styled.h1`
    font-size: 32px;
    margin-bottom: 32px;
`;

export const Title = styled.h2`
    font-size: 24px;
    margin-bottom: 0;
    max-width: 400px;
    text-align: center;
    color: #133D5E !important;
`;

export const TicketForm = styled(Form)`
    width: 100%;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;

    /*@media (max-width: 500px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 16px;
        //background-color: white;
        //box-shadow: 0 -2px 10px 0 rgba(0,0,0,0.2);
    }*/
`;

/*
export const Input = styled`
    border: 1px solid #133D5E;
    border-radius: 2px;
    padding: 8px;
    margin-bottom: 8px;
    width: 100%;
`;
*/

export const SubmitButton = styled(FCRodinaButton)`
    margin-top: 16px;
    min-height: 50px;

    @media (max-width: 500px) {
        min-width: 100%;
        margin: 0;
    }
`;

export const PreviewImage = styled(Image)`
    max-width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
`;

export const PreviewImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   // margin-top: 16px;
    margin-bottom: 24px;
`;

export const ActionRules = styled.div`
    margin-top: -24px;
    font-size: 14px;
    opacity: 0.8;
    color: #133D5E;
`;
