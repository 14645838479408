import { useEffect, useState } from "react";
import { SocialLinksContainer, SocialLink, SocialIcon } from "./styled";
import { BASE_URL, getSocialLinks } from "../../../api";
import { SocialLinks } from "../../../types/promo";

export const SocialLinksList = () => {
    const [socialLinks, setSocialLinks] = useState<SocialLinks>(null);

    useEffect(() => {
        getSocialLinks().then(data => {
            setSocialLinks(data);
        });
    }, []);

    if (!socialLinks) {
        return null;
    }

    return (
        <SocialLinksContainer>
                {Object.keys(socialLinks).map((key) => {
                    return (
                        <SocialLink href={socialLinks[key]} target="_blank" rel="noreferrer" key={key}>
                            <SocialIcon src={`${BASE_URL}/static/images/social/blue/${key}.svg`} alt={key} />
                        </SocialLink>
                    );
                })}
        </SocialLinksContainer>
    );
}
