import React from "react";
import { Card, CardCoverImage, Content, NewsInfo, NewsInfoItem, NewsInfoItemText } from "./styled";
import {
    CalendarOutlined,
    TagOutlined,
} from '@ant-design/icons';

interface NewsCardProps {
    title: string;
    content: React.ReactNode;
    cover: string;
    date?: string;
    category?: string;
    url: string;
}

const { Meta } = Card;

export const NewsCard: React.FC<NewsCardProps> = ({
    title,
    cover,
    content,
    date,
    category,
    url,
}) => {
    const coverImage = cover ? <CardCoverImage alt={title} src={cover} /> : null;

    const contentElement = (
        <Content>
            {content}
        </Content>
    );
    return (
        <Card
            cover={coverImage}
            hoverable
            onClick={() => window.location.assign(url)}
        >
            <Meta title={title} description={contentElement} />
            <NewsInfo>
                <NewsInfoItem>
                    <CalendarOutlined />
                    <NewsInfoItemText>
                        {date}
                    </NewsInfoItemText>
                </NewsInfoItem>
                <NewsInfoItem>
                    <TagOutlined />
                    <NewsInfoItemText>
                        {category}
                    </NewsInfoItemText>
                </NewsInfoItem>
            </NewsInfo>
        </Card>
    );
}