import { InboxOutlined } from "@ant-design/icons";
import { UpdateHint, UploadContentContainer, UploadIcon, UploadText } from "./styled";
import { FC } from "react";


interface UploadContentProps {
    text: string;
    hint?: string;
}
export const UploadContent: FC<UploadContentProps> = ({
    hint, text
}) => {
    return (
        <UploadContentContainer>
            <UploadIcon>
                <InboxOutlined />
            </UploadIcon>
            <UploadText>
                {text}
            </UploadText>
            {hint && <UpdateHint>
                {hint}
            </UpdateHint>}
        </UploadContentContainer>
    );
}
